import storage from "local-storage-fallback";
import { decode } from "jsonwebtoken";
import { call, put, delay, putResolve, select } from "redux-saga/effects";
import { putWait } from "redux-saga-callback";
import { push } from "connected-react-router";
import {
  doSuccessLogin,
  doErrorLogin,
  doChangeResendSMSStatus,
  doAddIdentifier,
  doUnsuccessfulEncryptedLogin,
} from "../actions/session";
import { doFetchRechargeValues, doResetRechargeValues } from "../actions/rechargeValue";
import { doChangeNewTvRecharge } from "../actions/tvRecharge";
import { doChangeNewRecharge, doResetNewRecharge, doSaveRecharges } from "../actions/recharge";
import {
  doShowLoading,
  doHideLoading,
  doShowError,
  doHideModal,
  doShowModal,
  doShowSnackbar,
} from "../actions/ui";
import { requestSMS, requestLogin, requestCpfLogin } from "../api/session";
import channels from "../enum/channels";
import modals from "../enum/modals";
import smsStatuses from "../enum/smsStatuses";
import segments from "../enum/segments";
import { handleSendBrowserInfo } from "./fraudAnalysis";
import { doSaveFingerprint } from "../actions/fraudAnalysis";
import { getDeviceFingerprint } from "../lib/fraudAnalysis";
import { getChannel, logout } from "../lib/utils";
import { doEncryptedLoginRequest } from "../actions/analytics";
import { fetchCustomer, fetchPaymentMethods, requestEventAbandonedCart } from "../api/customer";
import { doResetCustomer, doSaveCustomer } from "../actions/customer";
import { customers } from "../enum/customers";
import { doFetchFeatures } from "../actions/feature";
import { getNewRecharge, getLatestOkRechargePerType } from "../selectors/recharge";
import { getAvailableBonus } from "../selectors/customer";
import { doSaveRechargeResume } from "../actions/rechargeResume";
import { fetchRecharges } from "../api/recharge";
import { getCardsPerType } from "../selectors/paymentMethod";
import { getGeoInformations } from "../api/geolocation";
import { isNoDataOrNoCreditChannel } from "../lib/channelValidator";
import { doSavePaymentMethods } from "../actions/paymentMethod";
import payments from "../enum/paymentMethods";
import { homeChannels } from "../enum/homeChannels";

function* handleRequestSMS(action) {
  yield put(doShowLoading());

  const { payload } = action;
  try {
    yield put(doAddIdentifier(payload.msisdn));
    yield call(requestSMS, payload);

    if (payload.target) yield put(push(payload.target));
  } catch (e) {
    yield put(doShowError("Erro ao enviar SMS.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRequestResendSMS(action) {
  yield put(doChangeResendSMSStatus(smsStatuses.SENDING));

  const { payload } = action;

  try {
    if (payload.showLoading) yield put(doShowLoading());
    yield put(doAddIdentifier(payload.msisdn));
    yield call(requestSMS, payload);
  } catch (e) {
    yield put(doShowError("Erro ao enviar SMS.", e));
  } finally {
    yield delay(1000);
    yield put(doChangeResendSMSStatus(smsStatuses.DONE));
    if (payload.showLoading) yield put(doHideLoading());
    yield put(doShowSnackbar("success", "Código de confirmação reenviado com sucesso."));
  }
}

function* handleRequestLogin(action) {
  const { payload } = action;
  if (payload.loading === true) yield put(doShowLoading());

  try {
    storage.clear();
    yield put(doResetCustomer());
    yield put(doResetRechargeValues());
    yield put(doResetNewRecharge());

    const [channelPath, channelName] = payload.channel;

    const shouldRequestGeoInformation = !isNoDataOrNoCreditChannel(channelName);
    const geoInformations = shouldRequestGeoInformation ? yield call(getGeoInformations) : null;

    sessionStorage.setItem("channel", channelName);

    const { data } = yield call(requestLogin, payload);
    storage.setItem("identifier", payload.msisdn);
    storage.setItem("segment", data.segment);
    sessionStorage.setItem("token", data.id);
    sessionStorage.setItem("sourceIp", geoInformations?.IPv4);
    sessionStorage.setItem("countryOrigin", geoInformations?.country_code);
    sessionStorage.setItem("channelPath", channelPath);

    const { data: customer } = yield call(fetchCustomer, data.identifier);
    const { data: paymentMethods } = yield call(fetchPaymentMethods);
    yield put(doSuccessLogin({ token: data.id, identifier: payload.msisdn }));
    yield put(doSaveCustomer(customer));

    yield putResolve(doFetchFeatures());

    yield call(requestEventAbandonedCart, {
      customer: { id: customer.id, credit_cards: getCardsPerType(paymentMethods, "credit") },
    });

    yield put(push(customer.status === customers.CREATED ? "numero" : "home"));
  } catch (e) {
    storage.clear();

    yield put(doErrorLogin());
    if (e.response && e.response.status === 400) {
      yield put(doShowModal(modals.INVALID_AUTHENTICATION_TOKEN));
    } else {
      yield put(push(payload.origin));
      yield delay(250);
      yield put(doShowError("Erro ao logar. Tente novamente.", e));
    }
  } finally {
    if (payload.loading === true) yield put(doHideLoading());
  }
}

function* handleRequestLoginDeviceToken() {
  try {
    const fingerprint = yield getDeviceFingerprint();

    yield put(doSaveFingerprint(fingerprint));

    if (process.env.REACT_APP_FRAUD_ANALYSIS_FLAG === "true") {
      yield handleSendBrowserInfo();
    }

    return null;
  } catch (e) {
    return null;
  }
}

function* handleRequestCpfLogin(action) {
  const { payload } = action;
  if (payload.loading === true) yield put(doShowLoading());

  try {
    storage.setItem("channelPath", "tv");
    storage.setItem("channel", channels[storage.getItem("channelPath")]);

    const { data } = yield call(requestCpfLogin, payload);
    storage.setItem("token", data.id);
    storage.setItem("identifier", payload.data.identifier);

    yield put(doSuccessLogin({ token: data.id, identifier: payload.data.identifier }));
    yield put(doChangeNewTvRecharge("target", payload.data.identifier));

    yield put(push(`../${storage.getItem("channelPath")}/home`));
  } catch (e) {
    storage.clear();

    yield put(push(payload.origin));
    yield delay(250);
    yield put(doShowError("Erro ao logar. Tente novamente.", e));
  } finally {
    if (payload.loading === true) yield put(doHideLoading());
  }
}

function* handleRequestEncryptedLogin(action) {
  const { payload } = action;

  try {
    storage.clear();
    yield put(doResetCustomer());

    const [channelPath, channelName] = payload.channel;
    const decodedPayload = decode(payload.data);

    const shouldRequestGeoInformation = !isNoDataOrNoCreditChannel(channelName);
    const geoInformations = shouldRequestGeoInformation ? yield call(getGeoInformations) : null;

    yield put(doResetRechargeValues());
    yield put(doResetNewRecharge());

    sessionStorage.setItem("channelPath", channelPath);
    sessionStorage.setItem("channel", channelName);
    sessionStorage.setItem("canRequestPixBundle", true);
    sessionStorage.setItem("canRequestDataBundle", true);

    const { data } = yield call(requestLogin, payload);
    const recipient = data.recipient || data.identifier;

    storage.setItem("identifier", data.identifier);
    sessionStorage.setItem("token", data.id);
    sessionStorage.setItem("partnerExternalId", data.partnerExternalId);
    storage.setItem("value", data.value);
    storage.setItem("segment", data.segment);
    storage.setItem("encryptedWithRecipient", recipient);
    storage.setItem("loginCardToken", data.token);
    sessionStorage.setItem("channel", data.portal || channelName);
    sessionStorage.setItem("sourceIp", geoInformations?.IPv4);
    sessionStorage.setItem("countryOrigin", geoInformations?.country_code);

    yield put(
      doSuccessLogin({
        token: data.id,
        partnerExternalId: data.partnerExternalId,
        identifier: data.identifier,
        recipient: data.recipient ? data.recipient : data.identifier,
        loginRechargeValue: data.value * 100,
        segment: data.segment,
        loginCardToken: data.token,
        channel: data.portal || channelName,
        channelPath,
      })
    );

    const { data: customer } = yield call(fetchCustomer, data.identifier);
    yield put(doSaveCustomer(customer));

    const { data: paymentMethods } = yield call(fetchPaymentMethods);
    yield put(doSavePaymentMethods(paymentMethods));

    yield call(requestEventAbandonedCart, {
      customer: {
        id: customer.id,
        credit_cards: getCardsPerType(paymentMethods, payments.CREDITO),
      },
    });

    if (decodedPayload.isPix || decodedPayload.paymentMethodToRepeat === payments.PIX) {
      const selectedPaymentMethod = paymentMethods.find(
        paymentMethod => paymentMethod.type === payments.PIX
      );

      if (selectedPaymentMethod) {
        yield put(
          doChangeNewRecharge("paymentMethod", {
            type: selectedPaymentMethod.type,
            data: {},
            label: selectedPaymentMethod.label,
          })
        );
      }
    }

    try {
      const recharges = yield call(fetchRecharges);
      yield put(doSaveRecharges(recharges.data));
    } catch (e) {
      console.error("Erro ao carregar as recargas.", e);
    }

    const value = data.value * 100;

    const channel = getChannel();

    const bonusText = yield select(state =>
      getAvailableBonus(
        value,
        state.customerState,
        null,
        null,
        null,
        channel,
        data.segment,
        data.identifier
      )
    );

    yield put(doSaveRechargeResume(value, bonusText));

    yield putResolve(doFetchFeatures());

    const shouldGoToHome =
      homeChannels.includes(channelName) &&
      !decodedPayload.isPix &&
      !decodedPayload.goToScheduleRecharge &&
      !decodedPayload.lastRechargeRepeat;

    const isPOS = data.segment === segments.pos;

    if (shouldGoToHome) {
      yield put(push("home"));
    } else {
      yield putWait(doFetchRechargeValues());

      if (decodedPayload.goToScheduleRecharge && customer.status === customers.ACTIVATED) {
        yield putResolve(doChangeNewRecharge("isScheduled", true));
      }

      if (
        !isPOS &&
        !decodedPayload.lastRechargeRepeat &&
        (channelName === channels.whatsapp || data.value)
      ) {
        const newRechargeValue = yield select(state => getNewRecharge(state));
        if (newRechargeValue.rechargeValue.isAvailable !== undefined) {
          storage.setItem("encryptedlogin", true);
        }
        yield put(push("numero"));
      } else if (decodedPayload.lastRechargeRepeat) {
        const lastOkCreditRecharge = yield select(state =>
          getLatestOkRechargePerType(state, payments.CREDITO)
        );

        const lastUsedCard = lastOkCreditRecharge.paymentMethod.source.params;

        yield put(
          doChangeNewRecharge("paymentMethod", {
            type: payments.CREDITO,
            data: { token: lastUsedCard.token, last: lastUsedCard.last, brand: lastUsedCard.brand },
            label: "Cartão de Crédito",
          })
        );

        yield put(push("confirm-repeat"));
      } else {
        yield put(push("numero"));
      }
    }
    yield put(doEncryptedLoginRequest(channelName, "sucesso"));
  } catch (e) {
    yield put(
      doUnsuccessfulEncryptedLogin({
        channel: sessionStorage.getItem("channel"),
        channelPath: sessionStorage.getItem("channelPath"),
      })
    );
    yield put(
      push(`../${sessionStorage.getItem("channelPath")}/landing`, { showDelayedModal: true })
    );
    yield put(doShowError("Erro ao logar. Tente novamente.", e));
    yield put(doEncryptedLoginRequest(payload.channel.channelName, "falha"));
  }
}

function* handleRequestLogout() {
  yield put(doHideModal());

  logout();

  yield put(doResetCustomer());
  yield put(doResetNewRecharge());
  yield put(push(`../${sessionStorage.getItem("channelPath")}`));
}

export {
  handleRequestSMS,
  handleRequestResendSMS,
  handleRequestLogin,
  handleRequestLoginDeviceToken,
  handleRequestLogout,
  handleRequestCpfLogin,
  handleRequestEncryptedLogin,
};
