import claroControleImg from "../images/ClaroControle.png";
import claroControleInverseImg from "../images/ClaroControleInverse.png";
import claroFlexImg from "../images/ClaroFlex.png";
import claroWhtasImg from "../images/ClaroWhatsapp.png";
import claroYoutubeImg from "../images/ClaroYoutube.png";
import claroPlusImg from "../images/plus_yellow.svg";
import claroWazeImg from "../images/ClaroWaze.png";
import claroVideo from "../images/ClaroVideo.png";
import claroMusica from "../images/ClaroMusica.png";
import claroFacebookImg from "../images/ClaroFacebook.png";
import claroInstagramImg from "../images/ClaroInstagram.png";
import claroTwitterImg from "../images/ClaroTwitter.png";
import controleFacil1 from "../images/claro_controle_39e90.png";
import controleFacil2 from "../images/claro_controle_49e90.png";

export const PROPS_UPSELL = [
  {
    type: "controle",
    conditions: {
      values: [],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: [
        "ALL",
        "-42",
        "-53",
        "-96",
        "-20",
        "-21",
        "-22",
        "-23",
        "-24",
        "-25",
        "-26",
        "-27",
        "-28",
        "-29",
      ],
    },
    generalProps: {
      buttonMargin: 12,
      closeLinkMargin: 21,
      heightImg: undefined,
      widthImg: undefined,
    },
    images: {
      headerImage: claroControleImg,
      logosImage: [
        { src: claroWhtasImg, alt: "claroWhtasImg" },
        { src: claroWazeImg, alt: "claroWazeImg" },
        { src: claroVideo, alt: "claroVideo" },
        { src: claroMusica, alt: "claroMusica" },
      ],
    },
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROCONTROLE_URL,
    rechargeValue: "49,90",
    bonus: 15,
  },
  {
    type: "flex",
    conditions: {
      values: [],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: [],
    },
    generalProps: {
      buttonMargin: 10,
      closeLinkMargin: 15,
      heightImg: "45%",
      widthImg: "45%",
    },
    images: {
      headerImage: claroFlexImg,
      logosImage: [
        { src: claroWhtasImg, alt: "claroWhtasImg" },
        { src: claroFacebookImg, alt: "claroFacebookImg" },
        { src: claroInstagramImg, alt: "claroInstagramImg" },
        { src: claroTwitterImg, alt: "claroTwitterImg" },
        { src: claroWazeImg, alt: "claroWazeImg" },
        { src: claroMusica, alt: "claroMusica" },
      ],
    },
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROFLEX_URL,
    rechargeValue: "39,90",
    bonus: 16,
  },
  {
    type: "normal",
    conditions: {
      values: [1500],
      includedChannels: ["CLARO_WEB_DESKTOP", "IOS", "ANDROID"],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["ACTIVATED"],
      ddds: ["ALL"],
    },
  },
  {
    type: "normal",
    conditions: {
      values: [1500, 2000],
      includedChannels: ["APP_MINHA_CLARO"],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["ACTIVATED"],
      ddds: ["ALL"],
    },
  },
  {
    type: "normal",
    conditions: {
      values: [1700, 2000],
      includedChannels: ["CLARO_WEB_DESKTOP"],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["ACTIVATED"],
      ddds: ["ALL"],
    },
  },
  {
    type: "normal",
    conditions: {
      values: [1700, 2000, 2500, 3000],
      includedChannels: ["IOS", "ANDROID"],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["ACTIVATED"],
      ddds: ["ALL"],
    },
  },
  {
    type: "normal",
    conditions: {
      values: [1700],
      includedChannels: [
        "APP_MINHA_CLARO",
        "MINHA_CLARO_WEB",
        "MINHA_CLARO_WEB_N_LOGADO",
        "APP_MINHA_CLARO_N_LOGADO",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["ACTIVATED"],
      ddds: ["ALL"],
    },
  },
  {
    type: "normal",
    conditions: {
      values: [2500, 3000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["ACTIVATED"],
      ddds: [
        "ALL",
        "-42",
        "-53",
        "-96",
        "-20",
        "-21",
        "-22",
        "-23",
        "-24",
        "-25",
        "-26",
        "-27",
        "-28",
        "-29",
      ],
    },
  },

  {
    type: "prezao",
    conditions: {
      values: [2000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: [
        "12",
        "13",
        "14",
        "15",
        "18",
        "22",
        "24",
        "27",
        "28",
        "31",
        "32",
        "33",
        "34",
        "35",
        "37",
        "38",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "51",
        "53",
        "54",
        "55",
        "65",
        "66",
        "68",
        "71",
        "73",
        "74",
        "75",
        "77",
        "79",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "92",
        "93",
        "94",
        "95",
        "96",
      ],
    },
  },
  {
    type: "controle_custom_1",
    conditions: {
      values: [3000, 3500, 5000, 10000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: ["42", "53", "96"],
    },
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROCONTROLE_CUSTOM1_URL,
    images: {
      headerImage: claroControleInverseImg,
      claroWhtasImg: { src: claroWhtasImg, alt: "claroWhtasImg" },
      claroYoutubeImg: { src: claroYoutubeImg, alt: "claroYoutubeImg" },
      claroPlusImg: { src: claroPlusImg, alt: "claroSomaImg" },
    },
    rechargeValue: "34,90",
    bonus: 12,
  },
  {
    type: "controle_custom_2",
    conditions: {
      values: [4000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: ["ALL"],
    },
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROCONTROLE_CUSTOM2_URL,
    images: {
      headerImage: claroControleInverseImg,
      claroWhtasImg: { src: claroWhtasImg, alt: "claroWhtasImg" },
      claroYoutubeImg: { src: claroYoutubeImg, alt: "claroYoutubeImg" },
      claroPlusImg: { src: claroPlusImg, alt: "claroSomaImg" },
    },
    rechargeValue: "34,90",
    bonus: 15,
  },
  {
    type: "controle_facil_1",
    conditions: {
      values: [],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: [],
    },
    image: controleFacil1,
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROCONTROLE_FACIL1_URL,
  },
  {
    type: "controle_facil_2",
    conditions: {
      values: [],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: [],
    },
    image: controleFacil2,
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROCONTROLE_FACIL2_URL,
  },
  {
    type: "normal_custom",
    conditions: {
      values: [2500],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: ["42", "53", "96", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"],
    },
  },
];
