const channels = {
  "no-credit": "CLARO_WAP_NUANCE",
  "claro-box": "CLARO_BOX",
  recarga: "CLARO_WEB_DESKTOP",
  tv: "CLARO_TV_WEB_DESKTOP",
  "minha-claro": "MINHA_CLARO_WEB",
  minhaclaro_web: "MINHA_CLARO_WEB",
  minhaclaro_app: "APP_MINHA_CLARO",
  "claro-vip": "CLARO_VIP_WEB",
  whatsapp: "CLARO_WHATSAPP",
  claro_flex: "CLARO_FLEX",
  ios: "IOS",
  android: "ANDROID",
  take_rcs: "CLARO_RCS",
  claro_migracao_oi: "CLARO_MIGRACAO_OI",
  cadastro_chip: "CADASTRO_CHIP",
  clarouraone: "CLARO_URA_ONE",
  claro_fb: "CLARO_FB",
  claro_dma: "CLARO_DMA",
  sms_claro: "RECARGA_SMS_CLARO_BR",
  claro_nodata: "CLARO_NO_DATA_SMS",
  claro_ts: "CLARO_NC_TS",
  rcs_claro_br: "RCS_CLARO_BR",
  appbot_claro_br: "APPBOT_CLARO_BR",
  whatsapp_claro_br: "WHATSAPP_CLARO_BR",
  tiktok_claro: "TIKTOK_CLARO_RECARGA",
  claro_flex_inad: "CLARO_FLEX_INAD",
  minhaclaro_web_nlog: "MINHA_CLARO_WEB_N_LOGADO",
  minhaclaro_app_nlog: "APP_MINHA_CLARO_N_LOGADO",
  claro_nc_superbanner: "CLARO_NC_SUPERBANNER",
  minhaclaro_dma: "MINHACLARO_DMA",
  claro_gbm: "CLARO_GBM",
  controle_facil: "CLARO_CONTROLE_FACIL",
  controle_web: "CLARO_CONTROLE_WEB",
};

export default channels;
